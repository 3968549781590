//#region MODULES
import { imageFormatCDN, imageSizeCDN } from '../globals';
import { useSetting } from '../globals';
import Anchor from './Anchor';
import React, { useEffect, useState } from 'react';
//#endregion

/**@param {import('./Types').Image} props*/
const Image = (props) => {
	//#region VARIABLES
	const { alt = '', anchorClass, breakpoints = {}, className, href, onError, placeholder, rel, sizes = ['200'], src = '', style, target, ...otherProps } = props;
	const { device } = useSetting();
	const { isBrowser, isTablet } = device;
	const source = { browser: '', mobile: '', tablet: '' };
	const scale = { browser: {}, mobile: {}, tablet: {} };
	const [error, setError] = useState();
	const currentDevice = isBrowser ? 'browser' : isTablet ? 'tablet' : 'mobile';
	//#endregion

	//#region FUNCTIONS
	useEffect(() => {
		if (error) setError();
	}, [src]);

	function getAnchor(content) {
		if (!href) return content;
		return (
			<Anchor href={href} target={target} className={anchorClass} rel={rel}>
				{content}
			</Anchor>
		);
	}
	//#endregion

	//#region PRE-RENDER
	if (error) {
		console.error(`Failed to load Image with source: ${src?.browser || src}`);
	}

	if (typeof src === 'string') {
		source.browser = src;
		source.mobile = src;
		source.tablet = src;
	} else {
		source.browser = src.browser || src.tablet || src.mobile || '';
		source.mobile = src.mobile || src.tablet || src.browser || '';
		source.tablet = src.tablet || src.browser || src.mobile || '';
	}

	if (Array.isArray(sizes)) {
		const [width, height] = sizes;
		scale.browser = { width: width, height: height || width };
		scale.mobile = { width: width, height: height || width };
		scale.tablet = { width: width, height: height || width };

		if (imageFormatCDN(source.browser)) source.browser = imageSizeCDN(source.browser, width);
		if (imageFormatCDN(source.tablet)) source.tablet = imageSizeCDN(source.tablet, width);
		if (imageFormatCDN(source.mobile)) source.mobile = imageSizeCDN(source.mobile, width);
	} else {
		const [widthB, heightB] = sizes.browser || [];
		scale.browser = { width: widthB, height: heightB || widthB };
		if (imageFormatCDN(source.browser)) source.browser = imageSizeCDN(source.browser, widthB);

		const [widthM, heightM] = sizes.mobile || [];
		scale.mobile = { width: widthM, height: heightM || widthM };
		if (imageFormatCDN(source.mobile)) source.mobile = imageSizeCDN(source.mobile, widthM);

		const [widthT, heightT] = sizes.tablet || [];
		scale.tablet = { width: widthT, height: heightT || widthT };
		if (imageFormatCDN(source.tablet)) source.tablet = imageSizeCDN(source.tablet, widthT);
	}

	const { mobile: sm = 576, tablet: md = 1024 } = breakpoints;
	const { browser, mobile, tablet } = source;
	const current = source[currentDevice];
	const currentSize = scale[currentDevice];

	const imgProps = {
		...currentSize,
		...otherProps,
		alt: alt,
		className: className,
		onError: () => {
			setError('/static/img/no_img.webp');
			if (onError) onError();
		},
		src: error || current,
		style: style,
	};

	if (error) imgProps['aria-invalid'] = true;
	//#endregion

	//#region RENDER
	return getAnchor(
		<picture>
			{browser && <source media={`(min-width: ${md}px)`} srcSet={error || browser} />}
			{tablet && <source media={`(min-width: ${sm}px)`} srcSet={error || tablet} />}
			{mobile && <source srcSet={error || mobile} />}
			<img {...imgProps} />
		</picture>
	);
	//#endregion
};

Image.displayName = 'Image';
export default Image;
