const site = {
	core: 'web47',
	env: document.querySelector('#env_menu').value,
	lang: document.querySelector('#lang_menu').value,
	sources: {
		cdn: document.querySelector('#cdn_menu').value,
		url: document.querySelector('#url_menu').value,
	},
	tag: document.querySelector('#site_menu').value,
};

const imageSizes = [60, 200, 300, 320, 487, 640, 750, 828, 1080, 1200, 1920, 2048, 3840];

const controlUrl = (url = '', origin = null) => {
	let source = url;

	if (origin && !url.includes('http')) {
		if (url.startsWith('/')) source = `${origin}${url}`;
		else source = `${origin}/${url}`;

		if (source.includes('n~')) {
			source = source.replace(/n~/g, 'ni');
		}
	} else if (site.env === 'DEV' && url.startsWith('/') && !url.includes('static') && !origin) {
		source = 'https://dev.elmotorista.es' + source;
	} else if (site.env === 'DEV' && !url.startsWith('http') && !url.startsWith('/')) {
		source = 'https://dev.elmotorista.es/' + source;
	} else if (site.env === 'DEV' && !origin && url.includes('https://elmotorista.es')) {
		source = url.replace('elmotorista.es', 'dev.elmotorista.es');
	}

	return `${source}`;
};

function imageSizeCDN(source, newSize, check = true, allowedSizes = undefined) {
	if (!check) return source;
	const sizesCDN = allowedSizes ?? imageSizes;
	if (!imageFormatCDN(source)) throw new Error(`El formato de la imagen no es válido ('${source}')`);
	const imgWithoutSize = source.slice(0, source.lastIndexOf('_') + 1);
	const imgExtension = source.slice(source.lastIndexOf('.'));
	let newSource = source;

	if (newSize === 'Smaller') {
		let size = Math.min(...sizesCDN);
		newSource = imgWithoutSize + size + imgExtension;
	} else if (newSize === 'Larger') {
		let size = Math.max(...sizesCDN);
		newSource = imgWithoutSize + size + imgExtension;
	} else {
		if (!sizesCDN.includes(newSize)) newSize = imageSizes.find((s) => s > newSize);

		newSource = imgWithoutSize + newSize + imgExtension;
	}

	return newSource;
}

function imageFormatCDN(source) {
	const lineIndex = source.lastIndexOf('_');
	const dotIndex = source.lastIndexOf('.webp');
	if (lineIndex < 0 || dotIndex < 0) return false;

	let size = source.slice(lineIndex + 1, dotIndex);

	if (isNaN(size)) return false;

	return true;
}

function getDevice() {
	const width = window.outerWidth;

	const sizes = {
		min: width < 576,
		xs: width >= 576 && width < 640,
		sm: width >= 640 && width < 768,
		md: width >= 768 && width < 1024,
		lg: width >= 1024 && width < 1280,
		xl: width >= 1280 && width < 1400,
		max: width >= 1280,
	};

	return {
		...sizes,
		isBrowser: sizes.max || sizes.xl,
		isMobile: sizes.min || sizes.xs,
		isTablet: sizes.sm || sizes.md || sizes.lg,
		width,
	};
}

function useSetting() {
	return { device: getDevice() };
}

export { site, controlUrl, imageSizeCDN, imageFormatCDN, useSetting };
