//#region MODULES
import CSS from './index.module.css';
import React from 'react';
//#endregion

const SideMenu = (props) => {
	//#region VARIABLES
	const { children, className, id = 'SideMenu', offsetX = 0, onLostFocus, overlay = true, overlayStyle, style = {}, visible = false, width = '25%' } = props;
	const ID = id;
	//#endregion

	//#region FUNCTIONS
	function onOverlayClick() {
		if (typeof onLostFocus === 'function') {
			onLostFocus();
		}
	}
	//#endregion

	//#region ACCESIBILITY
	const menuProps = {
		className: [CSS.main, visible ? CSS.shown : CSS.hidden, className].filter(Boolean).join(' '),
		id: `menu_${ID}`,
		role: 'menu',
		style: { ...style },
	};
	if (visible && width) menuProps.style.width = width;
	if (offsetX) menuProps.style.left = offsetX;
	const overlayProps = {
		className: visible ? CSS.overlay : CSS.noOverlay,
		id: `overlay_${ID}`,
		onClick: onOverlayClick,
	};
	if (typeof overlayStyle === 'string') overlayProps.className += ` ${overlayStyle}`;
	else overlayProps.style = overlayStyle;
	//#endregion

	//#region RENDER
	return (
		<>
			{overlay && <div {...overlayProps} />}
			<aside {...menuProps}>{visible && <>{children}</>}</aside>
		</>
	);
	//#endregion
};

SideMenu.displayName = 'SideMenu';
export default SideMenu;
