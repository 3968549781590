import React from 'react';
import ReactDOM from 'react-dom/client';
import Menu from './menu';
import i18n from './config/i18n';
import { I18nextProvider } from 'react-i18next';

const elements = document.querySelectorAll('#menu');

for (let i = 0; i < elements.length; i++) {
	const element = elements[i];
	const root = ReactDOM.createRoot(element);
	root.render(
		<React.StrictMode>
			<I18nextProvider i18n={i18n}>
				<Menu />
			</I18nextProvider>
		</React.StrictMode>
	);
}
