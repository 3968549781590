//#region MÓDULOS
import IconMap from '../config/data/Icon.map.json';
import { site, controlUrl } from '../globals';
//#endregion

/**@param {import('./Types').IconProps} props*/
const Icon = (props) => {
	//#region VARIABLES
	const { alt, className, color, size, src, style, title, ...other } = props;
	//#endregion

	//#region PRE-RENDER
	let altText = alt ?? title ?? src;
	const attributes = {
		alt: altText,
		className: className,
		src: controlUrl(src, site.sources.cdn),
		style,
		title,
		...other,
	};
	let imgSize = size;

	let source = IconMap[src];
	if (!src) source = IconMap['no-image'];
	if (!source?.duotone && color) {
		attributes.className = [CSS[color], className].filter(Boolean).join(' ');
	}
	if (source?.url) {
		attributes.src = controlUrl(source.url, site.sources.cdn);
		if (source.size) imgSize = imgSize || source.size;
		if (source.alt) attributes.alt = source.alt;
		if (source.title) attributes.title = source.title;
	}

	if (attributes.onClick) {
		attributes.style = { ...attributes.style, cursor: 'pointer' };
	}

	let [width, height] = imgSize?.split('x') ?? [];
	attributes.width = width;
	attributes.height = height ?? width;
	attributes.style = {
		...attributes.style,
		height: attributes.height + 'px',
		width: attributes.width + 'px',
		minWidth: attributes.width + 'px',
		minHeight: attributes.height + 'px',
	};
	//#endregion

	//#region RENDER
	return <img alt="" {...attributes} />;
	//#endregion
};

Icon.displayName = 'Icon';
export default Icon;
