//#region VARIABLES ESTÁTICAS
const reactPaths = [
	'/account',
	'/alquiler',
	'/citas-taller',
	'/moto',
	'/motos',
	'/online',
	'/product',
	'/search',
	'/shop-bicis',
	'/shop-motos',
	'/shop',
	'/tiendas',
	'/vehicle',
	'/vehicles',
];
//#endregion

const Anchor = (props) => {
	//#region VARIABLES
	const { as, href, legacyBehavior, locale, passHref, prefetch, replace, scroll, shallow, children, ...otherProps } = props;

	const anchorProps = { ...otherProps };

	const path = typeof href === 'string' ? href : href?.pathname;
	const internal = path && reactPaths.some((route) => path.startsWith(route));
	//#endregion

	//#region RENDER CONDICIONAL
	if (path) {
		if (internal) {
			const linkProps = { as, href, legacyBehavior, locale, passHref, prefetch: false, replace, scroll, shallow };
			const definedProps = {};
			Object.keys(linkProps).forEach((key) => {
				if (linkProps[key]) definedProps[key] = linkProps[key];
			});

			return (
				<a {...definedProps} {...anchorProps}>
					<>{children}</>
				</a>
			);
		}

		const safePath = path.startsWith('/') && !path.endsWith('/') ? path + '/' : path;
		return (
			<a href={safePath} {...anchorProps}>
				{children}
			</a>
		);
	}
	return <span {...anchorProps}>{children}</span>;
	//#endregion
};

export default Anchor;
