import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import { site } from '../globals';

export const namespaces = ['Header'];

export function loadLang(lang) {
	const translations = {};

	namespaces.forEach((namespace) => {
		try {
			const data = require(`./translations/${lang}/${namespace}.json`);
			translations[namespace] = data;
		} catch (error) {}
	});

	return translations;
}

let resources = {
	en: loadLang('en'),
	es: loadLang('es'),
	pt: loadLang('pt'),
};

/* CARGANDO RECURSOS DESDE BACKEND */
i18n.use(initReactI18next).init(
	{
		initImmediate: true,
		lng: site.lang,
		fallbackLng: site.lang,
		fallbackNS: ['Header'],
		debug: true,
		preload: [site.lang],
		interpolation: {
			escapeValue: false,
		},
		ns: ['Header'],
		defaultNS: ['Header'],
		resources,
		react: {
			useSuspense: true,
		},
	},
	(error, t) => error && console.error(error)
);

export default i18n;
