//#region MÓDULOS
import { site, useSetting } from '../globals';
import { useTranslation } from 'react-i18next';
import Anchor from '../utils/Anchor';
import CSS from './index.module.css';
import Icon from '../utils/Icon';
import Image from '../utils/Image';
import React, { useState } from 'react';
import SideMenu from './sideMenu';
//#endregion

//#region ESTÁTICOS
const LOGO = {
	browser: `${site.sources.cdn}/wp-content/logo/logo-blanco.webp`,
	tablet: `${site.sources.cdn}/wp-content/logo/Elmotorista-300x60-tablet.webp`,
	mobile: `${site.sources.cdn}/wp-content/logo/Elmotorista-200x40-mvl.webp`,
};
//#endregion

const Menu = () => {
	//#region VARIABLES
	const { t } = useTranslation('Header');
	const { device } = useSetting();
	const { isBrowser } = device;
	const [menu, setMenu] = useState('');
	const [backOption, setBackOption] = useState([{ id: 'main' }]);
	//#endregion

	//#region FUNCIONES
	const showDrawer = () => {
		setMenu('main');
	};
	//#endregion

	//#region PRE-RENDER
	const content = t(`${site.tag}.menu`, { returnObjects: true, defaultValue: [] });
	const mainWidth = 450;
	const nestWidth = 300;
	const mobileWidth = 340;

	const mainMenu = {
		className: CSS.menu,
		width: isBrowser ? mainWidth : mobileWidth,
		style: { zIndex: 9999, display: 'flex', flexFlow: 'column', overflow: 'auto' },
		overlayStyle: { zIndex: 9999 },
		visible: menu !== '',
		onLostFocus: () => setMenu(''),
	};

	const closeIcon = {
		size: '24',
		src: 'xmark-black',
		style: { cursor: 'pointer' },
		onClick: () => setMenu(''),
	};

	const nestMenu1 = {
		width: isBrowser ? nestWidth : mobileWidth,
		style: { zIndex: 9999, backgroundColor: '#f5f5f5', overflow: 'auto' },
		overlayStyle: { display: 'none' },
		offsetX: isBrowser ? mainWidth : 0,
	};

	const nestMenu2 = {
		style: { zIndex: 9999, backgroundColor: '#ededed', overflow: 'auto' },
		width: isBrowser ? nestWidth : mobileWidth,
		offsetX: isBrowser ? mainWidth + nestWidth : 0,
		overlayStyle: { display: 'none' },
	};
	//#endregion

	//#region RENDER
	return (
		<div className={CSS[site.tag]}>
			<div className={CSS.container}>
				<div className={CSS.burgerMenu} onClick={showDrawer}>
					<Icon size={isBrowser ? '45' : '33'} src={isBrowser ? 'menu-hamburguer-white' : 'menu-hamburguer-white-mvl'} />
					{isBrowser && <span>{t(`${site.tag}.title`)}</span>}
				</div>
				<div className={CSS.logo}>
					<Image sizes={{ browser: ['126', '60'], tablet: ['180', '40'], mobile: ['180', '40'] }} href="/" src={LOGO} />
				</div>
			</div>

			<SideMenu {...mainMenu}>
				<div className={CSS.title}>
					<Icon {...closeIcon} />
				</div>
				<div className={CSS.list}>
					{content.map(({ id, icon, text, href, elements, rrss = false }) => {
						if (!href && !elements) {
							return (
								<div key={id} className={CSS.section}>
									{icon && <Icon size={isBrowser ? '27' : '15'} src={icon} />} {text}
								</div>
							);
						}
						if (href) {
							if (rrss) {
								return (
									<Anchor target="_blank" key={id} href={href} className={CSS.anchor} onClick={() => setMenu('')}>
										{icon && <Icon size={isBrowser ? '27' : '15'} src={icon} />} <span>{text}</span>
									</Anchor>
								);
							} else {
								return (
									<Anchor key={id} href={href} className={CSS.anchor} onClick={() => setMenu('')}>
										{icon && <Icon size={isBrowser ? '27' : '15'} src={icon} />} <span>{text}</span>
									</Anchor>
								);
							}
						}
						if (elements) {
							const toggleMenu = (back = false) => {
								if (back) backOption.pop();
								else backOption.push({ id, icon, text });

								setMenu((prev) => {
									if (prev === id || back) return backOption[backOption.length - 1].id;
									else return id;
								});
							};
							return (
								<React.Fragment key={id}>
									<div className={CSS.nest} onClick={() => toggleMenu()}>
										<div>
											{icon && <Icon size={isBrowser ? '27' : '15'} src={icon} />} {text}
										</div>
										<Icon size="10" src="chevron-right-black" />
									</div>
									<SideMenu {...nestMenu1} visible={menu.startsWith(id)}>
										<div
											className={CSS.backOption}
											onClick={() => {
												toggleMenu(true);
											}}
										>
											<div>
												{icon && (
													<Icon size={isBrowser ? '27' : '15'} src={backOption.length > 0 ? backOption[backOption.length - 1].icon : null} />
												)}{' '}
												{backOption.length > 0 ? backOption[backOption.length - 1].text : null}
											</div>
											<Icon size="10" src="chevron-left-black" />
										</div>

										<div className={CSS.nestedList}>
											{elements.map(({ id: nestID, icon, text, href, elements: nested, rrss = false }) => {
												if (!href && !nested) {
													return (
														<div key={nestID} className={CSS.section}>
															{icon && <Icon size="20" src={icon} />} {text}
														</div>
													);
												}
												if (href) {
													if (rrss) {
														return (
															<Anchor target="_blank" key={nestID} href={href} className={CSS.anchor} onClick={() => setMenu('')}>
																{icon && <Icon size="20" src={icon} />} <span>{text}</span>
															</Anchor>
														);
													} else {
														return (
															<Anchor key={nestID} href={href} className={CSS.anchor} onClick={() => setMenu('')}>
																{icon && <Icon size="20" src={icon} />} <span>{text}</span>
															</Anchor>
														);
													}
												}
												if (nested) {
													const toggleMenu = (back = false) => {
														if (back) backOption.pop();
														else backOption.push({ id, icon, text });

														setMenu((prev) => {
															if (prev === nestID || back) return backOption[backOption.length - 1].id;
															else return nestID;
														});
													};
													return (
														<React.Fragment key={nestID}>
															<div className={CSS.nest} onClick={() => toggleMenu()}>
																<div>
																	{icon && <Icon size="20" src={icon} />} {text}
																</div>
																<Icon size="10" src="chevron-right-black" />
															</div>
															<SideMenu {...nestMenu2} visible={menu.startsWith(nestID)}>
																{!isBrowser && (
																	<div
																		className={CSS.backOption}
																		onClick={() => {
																			toggleMenu(true);
																		}}
																	>
																		<div>
																			{icon && (
																				<Icon
																					size={isBrowser ? '27' : '15'}
																					src={backOption.length > 0 ? backOption[backOption.length - 1].icon : null}
																				/>
																			)}{' '}
																			{backOption.length > 0 ? backOption[backOption.length - 1].text : null}
																		</div>
																		<Icon size="10" src="chevron-left-black" />
																	</div>
																)}

																<div className={CSS.nestedList}>
																	{nested.map(({ id: thirdID, icon, text, href, rrss = false }) => {
																		if (!href) {
																			return (
																				<div key={thirdID} className={CSS.section}>
																					{icon && <Icon size="20" src={icon} />} {text}
																				</div>
																			);
																		}
																		if (href) {
																			if (rrss) {
																				return (
																					<Anchor
																						target="_blank"
																						key={thirdID}
																						href={href}
																						className={CSS.anchor}
																						onClick={() => setMenu('')}
																					>
																						{icon && <Icon size="20" src={icon} />} <span>{text}</span>
																					</Anchor>
																				);
																			} else {
																				return (
																					<Anchor key={thirdID} href={href} className={CSS.anchor} onClick={() => setMenu('')}>
																						{icon && <Icon size="20" src={icon} />} <span>{text}</span>
																					</Anchor>
																				);
																			}
																		}
																	})}
																</div>
															</SideMenu>
														</React.Fragment>
													);
												}
											})}
										</div>
									</SideMenu>
								</React.Fragment>
							);
						}
						return (
							<div key={id} className={CSS.section}>
								{text}
							</div>
						);
					})}
				</div>
			</SideMenu>
		</div>
	);
	//#endregion
};

Menu.displayName = 'Menu';
export default Menu;
